<template>
  <div class="home">
    <el-container>
      <el-header>
        <div style="height: 69px">
          <div style="float: left">
            <span><img src="../assets/image/home/cls-banner.png" /></span>
          </div>
          <div style="float: left; margin-left: 100px; width: 60%">
            <el-menu
              :default-active="activeIndex"
              style="border-bottom: aliceblue"
              router
              mode="horizontal"
              background-color="#000000"
              text-color="#ffffff"
              active-text-color="#ffd04b"
            >
              <el-menu-item index="Index"
                ><router-link
                  style="font-size: 16px; text-decoration: none"
                  to="/index"
                  >网站首页</router-link
                ></el-menu-item
              >
              <el-menu-item index="About"
                ><router-link
                  style="font-size: 16px; text-decoration: none"
                  to="/about"
                  >关于我们</router-link
                ></el-menu-item
              >
              <el-menu-item index="Product"
                ><router-link
                  style="font-size: 16px; text-decoration: none"
                  to="/product"
                  >产品中心</router-link
                ></el-menu-item
              >
              <el-menu-item index="Service"
                ><router-link
                  style="font-size: 16px; text-decoration: none"
                  to="/service"
                  >服务体系</router-link
                ></el-menu-item
              >
              <el-menu-item index="Partners"
                ><router-link
                  style="font-size: 16px; text-decoration: none"
                  to="/partners"
                  >合作伙伴</router-link
                ></el-menu-item
              >
              <el-menu-item index="News"
                ><router-link
                  style="font-size: 16px; text-decoration: none"
                  to="/news"
                  >新闻中心</router-link
                ></el-menu-item
              >
              <el-menu-item index="Technology"
                ><router-link
                  style="font-size: 16px; text-decoration: none"
                  to="/technology"
                  >技术研发</router-link
                ></el-menu-item
              >
              <el-menu-item index="Relation"
                ><router-link
                  style="font-size: 16px; text-decoration: none"
                  to="/relation"
                  >联系我们</router-link
                ></el-menu-item
              >
            </el-menu>
          </div>

          <div class="contact">
            <img src="../assets/image/home/telephone_banner.png" />
            <label>400-691-6788</label>
          </div>
        </div>
      </el-header>

      <el-main>
        <router-view />
      </el-main>
      <el-footer>
        <div class="buttom">
          <table>
            <tbody>
              <tr>
                <td>
                  <div style="padding-left: 270px" class="buttom-models">
                    <p class="models-text">
                      <img src="../assets/lxwm.png" />&nbsp;<strong
                        >联系我们</strong
                      >
                    </p>
                    <div class="models-text">
                      <p>18053702333</p>
                      <p>公司地址：山东济宁</p>
                      <p>电 &nbsp; 400-691-6788</p>
                      <p>传 &nbsp; 400-691-6788</p>
                      <p>企业邮箱：yunying@xiandaixingyi.cn</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="buttom-models" style="padding-top: 21px">
                    <p class="models-text">
                      <img src="../assets/ly.png" />&nbsp;<strong
                        >在线留言</strong
                      >
                    </p>
                    <div class="models-text">
                      <div style="width: 100%; margin-bottom: 20px">
                        <label>姓名</label>
                        <input type="text" id="attr_1" name="attr_1" />
                      </div>
                      <div style="width: 100%; margin-bottom: 20px">
                        <label>电话</label>
                        <input type="text" id="attr_1" name="attr_1" />
                      </div>
                      <div style="width: 100%">
                        <label>留言</label>
                        <textarea id="attr_3" name="attr_3"></textarea>
                      </div>
                      <el-button
                        type="info"
                        style="margin-top: 20px; margin-left: 50px"
                        >提交</el-button
                      >
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <p class="models-text" style="padding-top: 45px">
                      <img src="../assets/phone.png" />&nbsp;<strong
                        >客服电话</strong
                      >
                    </p>
                    <p
                      style="
                        color: #fff;
                        font-family: Verdana, Geneva, sans-serif;
                      "
                      class="font30"
                    >
                    400-691-6788
                    </p>
                    <div style="width: 200px" class="models-text">
                      <img src="../assets/rwm.png" class="image" />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <p class="buttom-p"></p>
          <span style="padding-left: 650px" class="models-text">
            备案号：
          </span>
          <a class="models-text" href="https://beian.miit.gov.cn" target="_blank"> 鲁ICP备18035581号-3 </a>
          <span class="models-text" style="padding-left: 10px">
            Copyright © 2021-2022 山东现代星驿商贸有限公司 版权所有
          </span>
        </div>
      </el-footer>
    </el-container>
  </div>

  <!-- <div class="home">
    <img alt="Vue logo" src="../assets/logo.png" />
    <HelloWorld msg="Welcome to Your Vue.js App" />
  </div> -->
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
    // HelloWorld,
  },

  data() {
    return {
      activeIndex: "Index",
    };
  },
  watch: {
    $route(to) {
      // 浏览器回退选中菜单
      if (to.name == "NewsDetails") {
        this.activeIndex = "News";
      } else if (to.name == "ServiceDetails") {
        this.activeIndex = "Service";
      } else if (to.name == "ProductDetails") {
        this.activeIndex = "Product";
      } else {
        this.activeIndex = to.name;
      }
    },
  },
  methods: {},
  mounted() {
    //刷新时选中菜单
    if (this.$route.name == "NewsDetails") {
      this.activeIndex = "News";
    } else if (this.$route.name == "ServiceDetails") {
      this.activeIndex = "Service";
    } else if (this.$route.name == "ProductDetails") {
      this.activeIndex = "Product";
    } else {
      this.activeIndex = this.$route.name;
    }
    //处理页面跳转后显示最底部
    this.$router.afterEach(() => {
      window.scrollTo(0, 0);
    });
  },
};
</script>

<style scoped>
@import "../assets/css/index.css";
.html,
body {
  margin: 0;
  padding: 0;
}
.home {
  height: 100%;
}

.el-header {
  background-color: #000000;
  color: var(--el-text-color-primary);
  text-align: center;
  padding: 0px;
  height: 68px;
  /* line-height: 69px; */
}

/* .el-aside {
    background-color: #d3dce6;
    color: var(--el-text-color-primary);
    text-align: center;
    line-height: 200px;
  } */

.el-main {
  /* background-color: #e9eef3; */
  color: var(--el-text-color-primary);
  text-align: center;
  /* line-height: 160px; */
}

.el-container {
  margin-bottom: 40px;
  height: 100%;
}

/* .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }

  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  } */
.contact {
  float: right;
  padding-right: 35px;
  text-align: right;
  padding-top: 12px;
  font-size: 18px;
  color: #fff;
}
.contact img {
  padding-right: 8px;
  padding-top: 6px;
}
.contact label {
  margin-top: -17px;
}
.el-footer {
  padding: 0px;
}
.el-main {
  padding: 0px;
}
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  text-decoration: underline;
}
</style>
