import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    redirect: "/index",
    children: [
      {
        path: "/index",
        name: "Index",
        component: () => import( "../views/Index.vue"),
      },
      {
        path: "/about",
        name: "About",
        component: () => import( "../views/About.vue"),
      },
      {
        path: "/product",
        name: "Product",
        component: () => import( "../views/Product.vue"),
      },
      {
        path: "/service",
        name: "Service",
        component: () => import( "../views/Service.vue"),
      },
      {
        path: "/partners",
        name: "Partners",
        component: () => import( "../views/Partners.vue"),
      },
      {
        path: "/news",
        name: "News",
        component: () => import( "../views/News.vue"),
      },
      {
        path: "/technology",
        name: "Technology",
        component: () => import( "../views/Technology.vue"),
      },
      {
        path: "/relation",
        name: "Relation",
        component: () => import( "../views/Relation.vue"),
      },
      {
        path: "/productDetails",
        name: "ProductDetails",
        component: () => import( "../views/ProductDetails.vue"),
      },
      {
        path: "/serviceDetails",
        name: "ServiceDetails",
        component: () => import( "../views/ServiceDetails.vue"),
      },
      {
        path: "/newsDetails",
        name: "NewsDetails",
        component: () => import( "../views/NewsDetails.vue"),
      },
    ],
    
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
